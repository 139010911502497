<template>
  <div v-if="page.length">
    <div class="relative">
      <img
        :src="isMobile ? page[0].img_m : page[0].img"
        class="width-100"
        alt=""
      >
      <div
        class="absolute"
        :style="{ top: `40%`, left: `0`, width: '100%', textAlign : 'center' }"
      >
        <div
          class="b"
          :class="isMobile ? 'f28' : 'f40'"
        >
          <span v-if="!isMobile">{{ page[0].t0 }}</span>
          {{ page[0].t1 }}
        </div>
        <div
          class=""
          :class="isMobile ? 'f16' : 'f20'"
          style="color:#666; margin-top: 20px; padding: 0 20px;"
        >
          {{ page[0].t2 }}
        </div>
      </div>
    </div>
    <div
      :class="isMobile ? 'ptb40 plr20' : 'm-auto'"
      :style="isMobile ? '' : 'width:1120px;padding:100px 0'"
    >
      <div
        class="bd bb bd-000"
        :class="isMobile ? 'f20 pb20 mb20' : 'f40 pb40 mb40'"
      >
        {{ page[1].t1 }}
      </div>
      <div :class="isMobile ? '' : 'flex justify-between'">
        <div>
          <div
            :class="isMobile ? 'f16' : 'f32'"
            style="font-weight:lighter"
          >
            {{ page[1].t2 }}
          </div>
          <div
            :class="isMobile ? 'f16' : 'f32'"
            style="font-weight:lighter; margin-top: 8px;"
          >
            {{ page[1].t3 }}
          </div>
        </div>
        <div :class="isMobile ? 'tc mt50' : ''">
          <img
            :src="site.service[1].img"
            alt=""
            :width="isMobile ? '150px' : '100px'"
          >
          <p class="tc mt10">
            联系官方客服
          </p>
        </div>
      </div>
    </div>
    <div
      :style="isMobile ? 'width:90%; margin: 0 auto; margin-bottom: 70px;' : 'width:1120px;padding:0;margin:0 auto 150px auto;'"
    >
      <img
        :src="page[1].img"
        class="width-100"
        alt=""
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: []
    }
  },

  async created () {
    const { data } = await this.fetch('/lianxi.json')
    this.page = data
  },

  methods: {
  }
}
</script>
